export class LocalStorageService {
  static saveData(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  static getData(key: string) {
    return localStorage.getItem(key);
  }

  static removeData(key: string) {
    localStorage.removeItem(key);
  }

  static clearData() {
    localStorage.clear();
  }

  static isStorageAvailable() {
    const test = "test";
    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  }
}

export enum LocalStorageKeys {
  GenerationDraft = "generation-draft",
  COOKIE_ACCEPTED = "cookie-accepted",
}
