import { useState, useEffect } from "react";
import { Button } from "@crowd/ui-kit";
import {
  LocalStorageKeys,
  LocalStorageService,
} from "../../../services/LocalStorage.service";
import styles from "./AcceptCookie.module.scss";
import { useIsTablet, useIsMobile } from "../../../utils/hooks/useMedia";

const AcceptCookie: React.FC = () => {
  const [isAccepted, setIsAccepted] = useState(true);
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  useEffect(() => {
    const cookieAccepted = Boolean(
      LocalStorageService.getData(LocalStorageKeys.COOKIE_ACCEPTED)
    );
    setIsAccepted(cookieAccepted);
  }, []);

  const handleAccept = () => {
    LocalStorageService.saveData(LocalStorageKeys.COOKIE_ACCEPTED, "true");
    setIsAccepted(true);
  };

  if (isAccepted) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <p className={styles.text}>
        Продолжая пользоваться порталом «Город идей», вы соглашаетесь
        {isMobile && <br />} на обработку файлов cookie{isMobile && <br />} с
        использованием метрических программ. Это поможет сделать портал еще
        более удобным{(isTablet || isMobile) && <br />} и полезным для миллионов
        пользователей.{" "}
        <a
          href="https://www.mos.ru/legal/rules/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          Подробнее
        </a>
      </p>
      <Button
        onClick={handleAccept}
        text="Принять"
        type="filled"
        classNames={styles.button}
      />
    </div>
  );
};

export default AcceptCookie;
