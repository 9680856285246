import NotificationsService, {
  DeleteOneQuery,
  ReadQuery,
} from "../../services/notificationsService";
import { ApiStatusCode } from "../../types/Common.interface";

export const NOTIFICATIONS_COUNT_ALL_UNREAD = "NOTIFICATIONS_COUNT_ALL_UNREAD";
export const NOTIFICATIONS_GET_PAGINATED = "NOTIFICATIONS_GET_PAGINATED";
export const READ_NOTIFICATION_SUCCESS = "READ_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DECREMENT_UNREAD_DIALOG_MESSAGE_COUNT =
  "DECREMENT_UNREAD_DIALOG_MESSAGE_COUNT";

export const decrementUnreadDialogMessageCount = (count) => ({
  type: DECREMENT_UNREAD_DIALOG_MESSAGE_COUNT,
  payload: count,
});

export const getNotifications = (page, size) => (dispatch) =>
  NotificationsService.getPaginated(page, size)
    .then((data) => {
      dispatch({
        type: NOTIFICATIONS_GET_PAGINATED,
        payload: data,
      });
    })
    .catch((err) => console.log(err));

export function readNotifications(query: ReadQuery) {
  return async function (dispatch) {
    try {
      const response = await NotificationsService.markAllRead(query.ids);
      if (response.data.status === ApiStatusCode.OK) {
        dispatch({
          type: READ_NOTIFICATION_SUCCESS,
          payload: { ids: query.ids },
        });
        dispatch(getCountAllUnread());
      } else {
        throw new Error(`Ошибка чтения уведомления: ${response.data.message}`);
      }
    } catch (error) {
      console.log(error);
    }
  };
}

export function removeNotification(query: DeleteOneQuery, onSuccess?) {
  return async function (dispatch) {
    try {
      const response = await NotificationsService.markClosed(query.id);
      if (response.data.status === ApiStatusCode.OK) {
        dispatch(getCountAllUnread());
        dispatch({
          type: DELETE_NOTIFICATION_SUCCESS,
          payload: { id: query.id },
        });
        onSuccess();
      } else {
        throw new Error(
          `Ошибка удаления уведомления: ${response.data.message}`
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
}

export const getCountAllUnread = () => (dispatch, getState) => {
  const loggedIn = getState().user?.loggedIn;
  if (!loggedIn) return undefined;

  return NotificationsService.countAllUnread()
    .then((data) => {
      dispatch({
        type: NOTIFICATIONS_COUNT_ALL_UNREAD,
        payload: data?.data,
      });
    })
    .catch((err) => console.error(err));
};
