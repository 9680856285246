import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { RootState } from "../../../../types/State.interface";
import CommentCard from "../../../presentational/Cards/CommentCard/CommentCard";
import { Comment } from "../../../../types/Comment.interface";
import { ElementObserver } from "../../../presentational/ElementObserver/ElementObserver";
import { ElementObserverItem } from "../../../presentational/ElementObserver/ElementObserverItem/ElementObserverItem";
import NotificationsService from "../../../../services/notificationsService";
import { useForceUpdate } from "../../../../utils/hooks/useForceUpdate";
import { getAnswersComments } from "../../../../store/actions/CommentActions";
import { getAllPersonalPosts } from "../../../../store/actions/BlogActions";
import EventsTrackParentIdContext from "../../../containers/EventsTrack/EventsTrackParentIdContext";
import EventsTrackWrapperScroll from "../../../containers/EventsTrack/wrappers/EventsTrackWrapperScroll";
import {
  AuthAction,
  AuthActionType,
  ComponentType,
} from "../../../../services/sudirService";
import { AppContext } from "../../../../Root";

const MainDiscussion = () => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const allPersonalPosts = useSelector(
    (state: RootState) => state.blog.allPersonalPosts
  );
  const answers = useSelector<RootState, Comment[] | null>(
    (state) => state.comment.answersComments
  );
  const rootComments = useSelector(
    (state: RootState) => state.comment.rootComments
  );
  const user = useSelector((state: RootState) => state.user.userDetails);
  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);
  const [recentRootComment, setRecentRootComment] = useState<Comment[] | null>(
    null
  );

  useEffect(() => {
    if ((!answers || answers.length === 0) && user?.loggedIn) dispatch(getAnswersComments());
    dispatch(getAllPersonalPosts({ onlyDisplayMain: true }));
  }, []);

  useEffect(() => {
    if (rootComments) {
      setRecentRootComment(getRecentRootComments());
    }
  }, [rootComments]);

  const getRecentRootComments = (): Comment[] => {
    const sharedComments = {};
    const result: Comment[] = [];

    rootComments
      ?.filter(
        (comment) =>
          !comment.deleted &&
          !comment.deletedByAuthor &&
          comment.author.id !== user.id
      )
      .forEach((comment) => {
        if (sharedComments.hasOwnProperty(comment.stageId)) {
          sharedComments[comment.stageId].push(comment);
        } else {
          sharedComments[comment.stageId] = [comment];
        }
      });

    Object.keys(sharedComments).forEach((key) => {
      const comments = sharedComments[key];
      if (comments?.length) {
        result.push(comments[0]);
      }
    });
    return result;
  };

  const markRead = (comment) => {
    if (!comment.notificationProcessed) {
      NotificationsService.markRead(comment.notificationId)
        .then(() => {
          comment.notificationProcessed = true;
        })
        .catch(() => {
          comment.notificationProcessed = false;
        })
        .finally(() => {
          forceUpdate();
        });
    }
  };

  const onShowForm = (comment, ev) => {
    ev && ev.preventDefault();
    const path = `/discussion/${comment.stageId}/${comment.id}?navigate=true&openForm=true`;

    if (!loggedIn) {
      return appContext.sudirService.authWithActions(
        [
          new AuthAction({
            component: ComponentType.MainPage,
            type: AuthActionType.Redirect,
            args: {
              redirectUrl: path,
            },
          }),
          new AuthAction({
            component: ComponentType.CommentCardList,
            type: AuthActionType.OpenCommentForm,
            args: {
              commentId: comment.id,
            },
          }),
        ],
        true
      );
    }

    history.push(path);
  };

  const renderComments = () => (
    <EventsTrackParentIdContext.Provider
      value={["PROJECT_DISCUSSION", "BLOCK_DISCUSSION_COMMENTS"]}
    >
      <EventsTrackWrapperScroll id={["DOCSCROLL"]} needParent>
        <div className="main__discussions-item">
          <h4 className="main__discussions-title">Комментарии в обсуждениях</h4>
          <div className="main__discussions-posts">
            {recentRootComment?.length ? (
              recentRootComment.map(
                (comment) =>
                  !comment.author.blocked && (
                    <CommentCard
                      key={comment.id}
                      comment={comment}
                      onShowForm={onShowForm}
                      authorType="participant"
                    />
                  )
              )
            ) : (
              <div className="main__discussions-posts_null">
                Комментариев пока нет
              </div>
            )}
          </div>
        </div>
      </EventsTrackWrapperScroll>
    </EventsTrackParentIdContext.Provider>
  );

  const renderAnswerList = () =>
    answers.slice(0, 3).map((comment) => (
      <ElementObserverItem data={comment} id={comment.id} key={comment.id}>
        <CommentCard
          comment={comment}
          commentInfoHintType="FULL"
          background={comment.notificationProcessed ? "white" : "#DBF4FF"}
        />
      </ElementObserverItem>
    ));

  const renderAnswers = () => (
    <div className="main__discussions-item">
      <Link to="/profile/answers" className="main__discussions-title">
        Ответы на ваши комментарии
      </Link>
      <div className="main__discussions-posts">
        {answers?.length ? (
          <ElementObserver
            delayBeforeNotify={1000}
            autoUnobserve
            onIntersect={markRead}
          >
            {renderAnswerList()}
          </ElementObserver>
        ) : (
          <div className="main__discussions-posts_null">
            На текущий момент нет ни одного ответа на ваши комментарии
          </div>
        )}
      </div>
      {answers?.length > 3 && (
        <div className="main__discussions-posts__all-answers">
          <Link to="/profile/answers">Все ответы</Link>
        </div>
      )}
    </div>
  );

  const renderBlogs = () => (
    <EventsTrackParentIdContext.Provider
      value={["PROJECT_DISCUSSION", "BLOCK_PRIVATE_BLOGS"]}
    >
      <EventsTrackWrapperScroll id={["DOCSCROLL"]} needParent>
        <div className="main__discussions-item">
          <h4 className="main__discussions-title">Личные блоги</h4>
          <div className="main__discussions-posts">
            {allPersonalPosts?.length ? (
              allPersonalPosts
                ?.slice(0, 2)
                .map((post) => (
                  <CommentCard
                    key={post.id}
                    blogPost={post}
                    authorType="author"
                  />
                ))
            ) : (
              <div className="main__discussions-posts_null">
                На текущий момент нет ни одного личного блога
              </div>
            )}
          </div>
        </div>
      </EventsTrackWrapperScroll>
    </EventsTrackParentIdContext.Provider>
  );

  return (
    <div className="main__discussions">
      <div className="main__discussions-top">
        <h3>Обсуждаем на проекте</h3>
        <div className="main__discussions-tabs">{/* {renderTabs()} */}</div>
      </div>
      <div className="main__discussions-body">
        {loggedIn && answers && answers.length > 0 && renderAnswers()}
        {renderComments()}
        {renderBlogs()}
      </div>
    </div>
  );
};

export default MainDiscussion;
